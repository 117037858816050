'use client';

import Button from "@/Components/Dashboard/UI/Button";
import {FormEventHandler, useState} from "react";
import {useForm} from "@inertiajs/react";
import {Field} from "@/Components/Catalyst/fieldset";
import {Input} from "@/Components/Catalyst/input";
import {TextLink} from "@/Components/Catalyst/text";
import {emitCustomEvent} from "react-custom-events";
import Api from "@/Api";
import MealPlanData = App.Data.MealPlanData;
import RecipeData = App.Data.RecipeData;

interface NewMealPlanFormProps {
  recipeQuickAdd?: RecipeData
}

export default function NewMealPlanForm(props: NewMealPlanFormProps) {
  const [mealPlan, setMealPlan] = useState<MealPlanData | null>()
  const [errors, setErrors] = useState<{
    name?: string[],
  } | null>()

  const {
    data,
    setData,
    processing,
    post
  } = useForm({
    name: '',
    recipeQuickAdd: props.recipeQuickAdd?.uuid
  });

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    Api.mealPlan.make(
      data.name,
      data.recipeQuickAdd,
    ).then((response) => {
      // Set MealPlan and
      setMealPlan(response?.data.mealPlan)
      emitCustomEvent('meal-plan::created', response?.data.mealPlan)
    }).catch((errors) => {
      setErrors(errors.response.data.errors)
    });
  };

  return (
    <div className="mx-auto max-w-2xl flex flex-col gap-6">
      {
        mealPlan &&
        <div className="bg-emerald-100 text-emerald-800 p-4 rounded-lg">
          Meal Plan {' '}
          <TextLink href={route('meal-plan', {mealPlan: mealPlan.uuid})}>
            {mealPlan.name}
          </TextLink>

          {' '}created successfully!

        </div>
      }
      {!mealPlan &&
        <form onSubmit={submit} className="flex flex-col gap-6 min-w-full">
          {props.recipeQuickAdd && <input type='hidden' name='recipe' value={data.recipeQuickAdd}/>}
          <Field>
            <Input
              type="text"
              name="name"
              value={data.name}
              onChange={(e) => {
                setErrors(null)
                setData('name', e.target.value)
              }}
              required
              autoComplete="name"
              className=""
              placeholder="Example: Next Week's Meal Plan"
            />
          </Field>

          <Button type='submit' disabled={processing || !data.name.trim().length}>
            Create Meal Plan
          </Button>
        </form>
      }
    </div>
  )
}
